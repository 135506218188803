import customisableworkflow from "../Assets/Images/KeyFeatures/customisableworkflow.svg"
import teamManagement from "../Assets/Images/KeyFeatures/teamManagement.svg"
import desktopNmobiles from "../Assets/Images/KeyFeatures/desktopNmobiles.svg"
import fullTeamConnectivity from "../Assets/Images/KeyFeatures/fullTeamConnectivity.svg"
// import quickCommunication from "../Assets/Images/KeyFeatures/quickCommunication.svg"
// import manageTasks from "../Assets/Images/KeyFeatures/manageTasks.svg"
import easyInterface from "../Assets/Images/KeyFeatures/easyInterface.svg"
import customizableViews from '../Assets/Images/KeyFeatures/customizableViews.svg'
import integrationSupport from '../Assets/Images/KeyFeatures/integrationSupport.svg'

export const keyFeatures = [
    { image: { src: customisableworkflow, alt: "Customisable workflows" }, heading: "Customisable workflows", statement: "Create workflows and processes that match your needs." },
    { image: { src: desktopNmobiles, alt: "desktop and mobile view" }, heading: "Desktop and mobile view", statement: "Work seamlessly across all of your devices." },

    // { image: { src: quickCommunication, alt: "quick Communication" }, heading: "Quick Communication", statement: "Need to add a comment or have a quick one-on-one chat with a team member, we got it all." },

    { image: { src: fullTeamConnectivity, alt: "Full-team inter-connectivity" }, heading: "Full-team inter-connectivity", statement: "Involve your entire team in your task management process, with optional approvals and custom access levels." },

    { image: { src: customizableViews, alt: "Customisable views" }, heading: "Customisable views", statement: "Switch between list, card, or progress views of your team's tasks." },
    { image: { src: integrationSupport, alt: "Integration support" }, heading: "Integration support", statement: "Connect Trekr to the tools you already use with a range of supported integrations and partnerships." },
    { image: { src: teamManagement, alt: "Team Management" }, heading: "Team Management", statement: "Track your team's performance metrics, worked hours, and engagement." },
    { image: { src: easyInterface, alt: "Easy-to-Learn Interface" }, heading: "Easy-to-learn interface", statement: "Trekr’s simple ticket-based system helps you put your focus where it’s needed." },
]