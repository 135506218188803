
import firstSlide1 from '../Assets/Images/WorkFlow/firstSlide1.png'
import firstSlide2 from '../Assets/Images/WorkFlow/firstSlide2.png'
import manage from "../Assets/Images/WorkFlow/manage.png"
// import dashboard from "../Assets/Images/WorkFlow/dashboard.png"
import customize from "../Assets/Images/WorkFlow/customize.png"
import customize1 from "../Assets/Images/WorkFlow/customize1.png"
import customize2 from "../Assets/Images/WorkFlow/customize2.png"


export const workFlows = [
    { image1: { src: firstSlide1, alt: "task view 1" }, image2: { src: firstSlide2, alt: "task view 2" }, title: "End-to-end project and task management", statement: "Log issues, track change requests, and manage projects from start to finish with Trekr’s straightforward and easy-to-use ticketing interface." },
    { image1: { src: customize, alt: "manage" }, title: "From design to deployment", statement: "Track new projects from suggestion to completion. Trekr’s custom workflow supports projects from the very first stages, right up until release." },
    { image1: { src: manage, alt: "dashboard" }, title: "Reporting and team management at a glance", statement: "Keep track of logged hours and manage your team’s workload. Trekr helps you visualise your team’s KPIs, with performance metrics updating automatically as your team completes tasks." },
    { image1: { src: customize1, alt: "customise1" }, image2: { src: customize2, alt: "customise2" }, title: "Customisable workflows", statement: "Create endless workflows, customised to suit you and your team." },

]