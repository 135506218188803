import React from 'react'
import styled from 'styled-components'
import trekr from "../../Assets/Images/trekr.svg"
import insta from "../../Assets/Images/Footer/insta.png"
// import linkedIn from "../../Assets/Images/Footer/linkedIn.png"
import facebook from "../../Assets/Images/Footer/facebook.png"
import twitter from "../../Assets/Images/Footer/twitter.png"
// import phoneIcon from "../../Assets/Images/Footer/call_white_24dp.svg"
import mailIcon from "../../Assets/Images/Footer/mail_white_24dp.svg"
import { Link } from 'react-router-dom'


const FooterWrap = styled.footer`

background-color: #0054D9;
height: auto;
/* scroll-snap-align: start; */
color: white;
padding-bottom: 2px;


`

const Footer = ({focusMail}) => {
  return (
    <FooterWrap>
      <div className='container mx-auto px-2 mt-2 pt-4 md:pt-12 lg:pt-16'>

        <div className='flex flex-row justify-between gap-2 items-center'>
          <img className='sm:pl-0' src={trekr} alt="trekr-logo" width={120} />

          <div className='inline-flex  gap-2 items-center justify-center'>
            {/* <a href="tel:0412345678" className='inline-block'>
              <img className='inline-block' src={phoneIcon} alt="phone" width="18" />
              {` 0412 345 678`}
            </a>
            | */}
            <a href="mailto:info@thetrekr.com" className={`inline-block hover:underline underline-offset-2 ${focusMail && "animate-pulse hover:animate-none"}`} id="contactSales">
              <img className='inline-block' src={mailIcon} alt="mail" width="20" />
              {` info@thetrekr.com`}
            </a>
          </div>

        </div>

        <div className='flex sm:flex-row flex-col sm:justify-between flex-wrap mt-4 mb-4'>
          <div className='flex gap-x-6 md:gap-x-8 lg:gap-x-16 gap-y-2 flex-row justify-center md:flex-wrap flex-nowrap'>
            {/* <div className=' pt-2 text-center md:text-left'>Status</div> */}
            {/* <div className=' pt-2 text-center md:text-left'>Security</div> */}
            <Link to="/terms-&-conditions">
              <div className=' pt-2 text-center md:text-left cursor-pointer hover:underline underline-offset-2'>Terms and conditions</div>
            </Link>
            <Link to="/privacy-policy">
              <div className=' pt-2 text-center md:text-left cursor-pointer hover:underline underline-offset-2'>Privacy policy</div>
            </Link>
            {/* <div className=' pt-2 text-center md:text-left'>Accessibility statement</div> */}
          </div>
          <div className='flex gap-6 items-baseline justify-center mr-0 md:mr-8 mt-4 sm:mt-0'>
            <div className='text-left  hover:scale-105 transition-all ease-in-out'>
              <img src={insta} alt="insta" width="35px" />
            </div>
            {/* <div className='text-left'>
              <img src={linkedIn} alt="linkedIn" width="35px" />
            </div> */}
            <div className='text-left hover:scale-105 transition-all ease-in-out'>
              <img src={facebook} alt="facebook" width="35px" />
            </div>
            <div className='text-left hover:scale-105 transition-all ease-in-out'>
              <img src={twitter} alt="twitter" width="35px" />
            </div>
          </div>

        </div>
      </div>
    </FooterWrap>
  )
}

export default Footer