export const pricingPlans = [
    {
        id: 1,
        tier: "Free",
        price: "0",
        duration: "",
        title: "For small team or individuals to manage project.",
        benefits: [
            { id: 1, name: "Up to 3 users" },
            { id: 2, name: "2GB storage" },
            // { id: 3, name: "Unlimited storage (12MB per file)" },
            // { id: 4, name: "Custom backgrounds & theme" },
            // { id: 5, name: "Unlimited cloud log" },
            // { id: 6, name: "User Pool" },
            { id: 3, name: "Project management" },
            { id: 4, name: "Task management" },
            { id: 5, name: "Custom Work Flow" },
            { id: 6, name: "Documents management" },
            { id: 7, name: "Reports" },
            { id: 8, name: "User Role and Permission" },
            { id: 9, name: "Android / iOS mobile App" },
        ],
        buttontext: "Get started",
        navigateTo: "https://dev.trekr.com.au/register",

    },
    {
        id: 2,
        tier: "Start Up",
        price: "4.50",
        duration: "Per User, Month",
        title: "For Orgainsations to manage work together and better collaboration.",
        benefits: [
            // { id: 1, name: "Unlimited Project" },
            { id: 1, name: "4-10 Users" },
            { id: 2, name: "5GB storage" },
            { id: 3, name: "Project management" },
            { id: 4, name: "Task management" },
            { id: 5, name: "Custom Work Flow" },
            { id: 6, name: "Documents management" },
            { id: 7, name: "Reports" },
            { id: 8, name: "User Role and Permission" },
            { id: 9, name: "Android / iOS mobile App" },
            // { id: 4, name: "Custom backgrounds & theme" },
            // { id: 5, name: "Unlimited cloud log" },
            // { id: 6, name: "User Pool" },
            // { id: 7, name: "Android and iOS mobile apps" },
            // { id: 8, name: "Project management" },
            // { id: 9, name: "Work Flow automation" },
            // { id: 10, name: "Documents Drive" },
            // { id: 11, name: "Reports" },
            // { id: 12, name: "Custom User Role and Permission" },
        ],
        buttontext: "Get Started",
        navigateTo: "https://dev.trekr.com.au/register",

    },
    // { id: 3, tier: "Premium", price: "10", title: "Lorem Ipsum is simply dummy text", benfits: "Lorem Ipsum is simply dummy text of the printing and typesetting industry", buttontext: "Try for free" },
    {
        id: 3,
        tier: "Business",
        price: "4.25",
        duration: "Per user, per month",
        title: "For Big Enterprise to manage high volume tasks and better collaboration",
        benefits: [
            // { id: 1, name: "Unlimited Project" },
            { id: 1, name: "11-50 users" },
            { id: 2, name: "10GB storage" },
            { id: 3, name: "Project management" },
            { id: 4, name: "Task management" },
            { id: 5, name: "Custom Work Flow" },
            { id: 6, name: "Documents management" },
            { id: 7, name: "Reports" },
            { id: 8, name: "User Role and Permission" },
            { id: 9, name: "Android / iOS mobile App" },
            // { id: 4, name: "Custom backgrounds & theme" },
            // { id: 5, name: "Unlimited cloud log" },
            // { id: 6, name: "User Pool" },
            // { id: 7, name: "Android and iOS mobile apps" },
            // { id: 8, name: "Project management" },
            // { id: 9, name: "Work Flow automation" },
            // { id: 10, name: "Documents Drive" },
            // { id: 11, name: "Reports" },
            // { id: 12, name: "Custom User Role and Permission" },
        ],
        buttontext: "Get Started",
        navigateTo: "https://dev.trekr.com.au/register",
    },
    {
        id: 4,
        tier: "Enterprise",
        price: "4",
        duration: "Per user, per month",
        title: "For Big Enterprise to manage high volume tasks and better collaboration",
        benefits: [
            // { id: 1, name: "Unlimited Project" },
            { id: 1, name: "Over 50 users" },
            { id: 2, name: "15GB storage" },
            { id: 3, name: "Dedicated server" },
            { id: 4, name: "Project management" },
            { id: 5, name: "Task management" },
            { id: 6, name: "Custom Work Flow" },
            { id: 7, name: "Documents management" },
            { id: 8, name: "Reports" },
            { id: 9, name: "User Role and Permission" },
            { id: 10, name: "Android / iOS mobile App" },
            // { id: 5, name: "Unlimited cloud log" },
            // { id: 6, name: "User Pool" },
            // { id: 7, name: "Android and iOS mobile apps" },
            // { id: 8, name: "Project management" },
            // { id: 9, name: "Work Flow automation" },
            // { id: 10, name: "Documents Drive" },
            // { id: 11, name: "Reports" },
            // { id: 12, name: "Custom User Role and Permission" },
        ],
        buttontext: "Get Started",
        navigateTo: "https://dev.trekr.com.au/register",
    },
]