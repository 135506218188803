import React from 'react'
import styled from 'styled-components'
import trekr from "../../Assets/Images/trekr.svg"
import { Button } from '../ReusableComponent/Button'
import homeScreenImg from "../../Assets/Images/HomeScreen/Group2088.png"
import bigCircle from "../../Assets/Images/HomeScreen/Group 2199@2x.png"
import circleFireFlies from "../../Assets/Images/HomeScreen/lf30_editor_2ec7xonl.json"
import evolutionStepsAnimation from "../../Assets/Images/HomeScreen/122824-project-evolution-steps.json"


const HomeScreenWrap = styled.div`
min-height: 100vh;
height: auto;
width: auto;
/* scroll-padding: 0; */
/* scroll-snap-align: start; */
overflow: hidden;

a {
  text-decoration: none;
  color: inherit;
}

`

const HomeScreen = ({ homePageRef, scrollToPricingRef, goToTrekrSystemRegister, goToTrekrSystemLogin }) => {

  return (
    <HomeScreenWrap ref={homePageRef}>


      <div className='grid isolate place-items-start'>

        <div className='z col-start-1 col-end-1 row-start-1 row-end-1'>

          <img className='w-[600px] md:w-[800px] lg:w-[1000px]' src={bigCircle} alt="big-circle" />
        </div>

        <div className='container mx-auto px-1 md:px-6 z-30 col-start-1 col-end-1 row-start-1 row-end-1'>

          <header className='z-50 pt-4 md:pt-8 lg:pt-8 flex flex-row gap-2 justify-between items-center mb-12 md:mb-24'>
            <img src={trekr} alt="trekr-logo" width={120} />
            <div className='flex flex-col sm:flex-row translate-y-6 sm:translate-y-0 gap-4 lg:gap-12 justify-center items-center'>
              <div className='text-xl cursor-pointer hover:text-cyan order-1 sm:order-none' onClick={scrollToPricingRef}>Pricing</div>

              <a href={goToTrekrSystemLogin()}>
                <Button>
                  Login
                </Button>
              </a>

            </div>
          </header>

          <div className='md:flex md:justify-center z-30 relative' >

            <div>
              <div className='font-semibold md:font-extrabold text-3xl sm:text-4xl md:text-[2.5rem] leading-[1.3] md:leading-[1.4] md:text-left mb-6 md:mb-12'>
                Transform the way you work with trekr
              </div>

              <div className='text-base md:text-left font-normal mb-6 md:mb-16'>
                Set your business up for success with an end-to-end solution to manage your progress, plan your team’s time and projects, and keep track of your business results
              </div>

              <div className='md:text-left pb-6'>
                <a href={goToTrekrSystemRegister()}>
                  <Button variant="fill">
                    Get Started
                  </Button>
                </a>
              </div>


              <div className='hidden lg:block'>
                <lottie-player
                  autoplay
                  loop
                  mode="normal"
                  src={JSON.stringify(evolutionStepsAnimation)}
                  style={{ maxWidth: "600px", mixHeight: "600px" }}
                ></lottie-player>
              </div>
            </div>



            <div className='grid isolate place-items-center -z-10 -my-32 md:-my-0  pointer-events-none '>
              <div className='z-20 col-start-1 col-end-1 row-start-1 row-end-1'>
                <img className='w-[1500px]' src={homeScreenImg} alt="homeScreen-img" />
              </div>

              <div className='-z-10 rotate-90
               col-start-1 col-end-1 row-start-1 row-end-1 
               w-[300px] h-[600px] md:h-[500px] lg:-translate-y-36 lg:h-[700px] lg:w-[400px] xl:-translate-y-52 '>
                <lottie-player
                  autoplay
                  loop
                  mode="normal"
                  src={JSON.stringify(circleFireFlies)}
                  height="600px"
                ></lottie-player>
              </div>
            </div>
          </div>

        </div>


      </div >


    </HomeScreenWrap >
  )
}

export default HomeScreen