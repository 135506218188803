import React from 'react'
import styled from 'styled-components'
import Plan from '../ReusableComponent/Plan'
import { pricingPlans } from "../../Constants/Pricings"

const PricingPlansWrap = styled.div`
/* scroll-snap-align: start; */
padding-top: 10px;
ul {
    padding-left: 20px;
}
ul > li {
    list-style: disc;
}

@media screen and (min-width: 767px){
    /* padding-top: 50px; */
}
`

const PricingPlans = ({ pricingRef, handleContactUsBtn }) => {
    return (
        <PricingPlansWrap ref={pricingRef}>
            <div className='p-3 mx-auto'>
                <div className='text-3xl pt-5 pb-3 text-cyan font-bold'>
                    Trekr pricing plans
                </div>

                <div className='text-lg pb-8 md:pb-14'>
                    Plans to suit teams of any size
                </div>

                <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 place-items-center items-stretch gap-4 md:gap-1 lg:gap-4 pb-10'>

                    {pricingPlans.map((pricingPlan) => {
                        return (
                            <div key={pricingPlan.id} className={``}>
                                <Plan
                                    tier={pricingPlan.tier}
                                    price={pricingPlan.price}
                                    title={pricingPlan.title}
                                    duration={pricingPlan.duration}
                                    benefits={pricingPlan.benefits}
                                    buttontext={pricingPlan.buttontext}
                                    navigateTo={pricingPlan.navigateTo}
                                    handleContactUsBtn={handleContactUsBtn} />
                            </div>
                        )
                    })}
                </div>


            </div>
        </PricingPlansWrap>
    )
}

export default React.memo(PricingPlans)